import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const page =
    (WrappedComponent) =>
        ({ staticContext }) => {
            const location = useLocation();

            let initData = null;
            if (staticContext) {
                initData = staticContext.data;
            } else if (window.__ROUTE_DATA__) {
                initData = window.__ROUTE_DATA__;
                delete window.__ROUTE_DATA__;
            }

            if (!staticContext) {
                useEffect(() => {
                    if (!initData) {
                        fetch(`data${location.pathname}`)
                            .then((r) => r.json())
                            .then(setPageData);
                    }
                }, [location]);
            }

            const [pageData, setPageData] = useState(initData);
            const loadGtagScript = () => {
                const script = document.createElement("script");
                script.src = `https://www.googletagmanager.com/gtag/js?id=G-EWF0CPM3GH`;
                script.async = true;
                document.body.appendChild(script);

                script.onload = () => {
                    window.dataLayer = window.dataLayer || [];
                    function gtag() {
                        window.dataLayer.push(arguments);
                    }
                    window.gtag = gtag;
                    gtag("js", new Date());
                    gtag("config", "G-EWF0CPM3GH");
                };

                const gtmScript = document.createElement("script");
                gtmScript.innerHTML = `
        (function(w,d,s,l,i){
            w[l]=w[l]||[];
            w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
            var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),
                dl=l!='dataLayer' ? '&l='+l : '';
            j.async=true;
            j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
            f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-5XNBJVVB');
    `;
                document.head.appendChild(gtmScript);

                
                const noscriptTag = document.createElement("noscript");
                noscriptTag.innerHTML = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5XNBJVVB"
                height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
                document.body.appendChild(noscriptTag);
            };
            const excludedPaths = ["/my-services", "/address/my", "/payment/history", "/payment/savedcards", "/my-home-services", "/customer-support", "/my-profile"];
            useEffect(() => {
                if (!excludedPaths.includes(location.pathname))
                loadGtagScript()
            }, [])
            return (
                pageData && <WrappedComponent pageData={pageData}></WrappedComponent>
            );
        };

export default page;
