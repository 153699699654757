import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import loadable from "@loadable/component";
import "./App.css";

const HomePageMobile = loadable(() => import("./pages/Home/HomePageMobile"));
const ServiceListPageMobile = loadable(() =>
  import("./pages/ServiceList/ServiceListPageMobile")
);
const PriceConfig2PageMobile = loadable(() =>
  import(
    "./pages/ServiceConfigurationPage/PriceConfig-2/PriceConfig2PageMobile"
  )
);
const PriceConfig1PageMobile = loadable(() =>
  import(
    "./pages/ServiceConfigurationPage/PriceConfig-1/PriceConfig1PageMobile"
  )
);
const PriceConfig2v2PageMobile = loadable(() =>
  import(
    "./pages/ServiceConfigurationPage/PriceConfig-2/PriceConfig2v2PageMobile"
  )
);
const CheckoutPaymentPageMobile = loadable(() =>
  import("./pages/Checkoutpages/CheckoutPaymentPage/CheckoutPaymentPageMobile")
);
const CheckputReviewPageMobile = loadable(() =>
  import("./pages/Checkoutpages/CheckoutReviewPage/CheckoutReviewPageMobile")
);
const CreateAccountPageMobile = loadable(() =>
  import("./pages/AccountPage/CreateAccount/CreateAccountPageMobile")
);
const SignupPageMobile = loadable(() =>
  import("./pages/AccountPage/Signup/SignupPageMobile")
);
const LoginPageMobile = loadable(() =>
  import("./pages/AccountPage/LoginPage/LoginPageMobile")
);
const AboutYou = loadable(() =>
  import("./pages/TellUsAbout/TellUsAboutPageMobile")
);
const MyHomeServicesPageMobile = loadable(() =>
  import("./pages/MyHomeServices/MyHomeServicesPageMobile")
);
const PaymentSavedCards = loadable(() =>
  import("./pages/Payments/PaymentCardsPage/PaymentCardsPageMobile")
);
const PaymentHistoryPageMobile = loadable(() =>
  import("./pages/Payments/PaymentHistory/PaymentHistoryPageMobile")
);
const MyProfilePageMobile = loadable(() =>
  import("./pages/MyProfile/MyProfilePageMobile")
);
const CustomerSupport = loadable(() =>
  import("./pages/CustomerSupportPage/CustomerSupportPageMobile")
);
const MyAddresses = loadable(() =>
  import("./pages/AddressesPage/MyAddressesPageMobile")
);
const SelectAddressPageMobile = loadable(() =>
  import("./pages/AddressesPage/SelectAddressPageMobile")
);
const MyServicesPageMobile = loadable(() =>
  import("./pages/MyServices/MyServicesPageMobile")
);
const InseptionReportPageMobile = loadable(() =>
  import("./pages/InspectionReport/InspectionReportPageMobile")
);
const ResetPassword = loadable(() =>
  import("./pages/AccountPage/resetPassword/ResetPassword")
);
const VerifyResetPassword = loadable(() =>
  import("./pages/VerifyResetPassword/VerifyResetPassword")
);
const ServicePlans = loadable(() =>
  import("./pages/servicePlans/ServicePlans")
);
const RoofTypesPage = loadable(() => import("./pages/RoofTypes/RoofTypes"));
const ErrorPage = loadable(() => import("./pages/ErrorPage/ErrorPage"));
const PayMyBillPage = loadable(() => import("./pages/PayMyBillPages/PayMyBill"));

const PayMyBillSummaryPage = loadable(() => import("./pages/PayMyBillPages/PayBillProcess/PayMyBillSummary"));

const PayMyBillCompletePage = loadable(() => import("./pages/PayMyBillPages/PayBillProcess/PayMyBillComplete"));

const App = () => {
  /*  async function PostRemovedServices() {

        const storedServices = localStorage.getItem('trackedServices');
        let parsedServices = [];

        if (storedServices) {
            //convert to the right format
            parsedServices = JSON.parse(storedServices);
            if (!Array.isArray(parsedServices)) {
                parsedServices = [parsedServices];
            }

        }

        const allServices = localStorage.getItem('allServices');
        let allServicesArray = [];

        // Parse allServices if it exists
        if (allServices) {
            allServicesArray = JSON.parse(allServices);
        }

        parsedServices.forEach(newSite => {
            // Find if the siteID already exists in allServices
            const existingSite = allServicesArray.find(site => site.siteID === newSite.siteID);

            if (existingSite) {
                // If the site exists, merge the services (avoid duplicates)
                newSite.Services.forEach(service => {
                    if (!existingSite.Services.some(existingService => existingService.name === service.name)) {
                        existingSite.Services.push(service);
                    }
                });
            } else {
                // If the site doesn't exist, add the entire site to allServices
                allServicesArray.push(newSite);
            }
        });


        try {
            const bodyData = {
                sites: allServicesArray
            };
            const response = await fetch(`/api/Services/PostRemovedService`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(bodyData),
            });
            if (response?.status === 401) {

                window.location.href = "/";
            }

            if (!response.ok) {
                const text = await response.text();
                setApiError(text);

            }

            const data = await response.json();
            if (data?.responseCode === 200) {

                localStorage.setItem('allServices', '');
                localStorage.setItem('services', '');
                localStorage.setItem('trackedServices', '');
                localStorage.setItem('checkout', '');
                //setServiceBookedPopup(true);
            }
        } catch (err) {

        }

        localStorage.setItem('allServices', '');
        localStorage.setItem('services', '');
        localStorage.setItem('trackedServices', '');
        localStorage.setItem('checkout', '');
    }
    const checkSession = async () => {
        try {
            const response = await fetch('/api/services/getSessionStatus');
            if (response.status === 401) {
                // Handle session expiration, redirect to login
                //alert('Session has expired. Please log in again.');

                const checkout = localStorage.getItem('checkout');

                if (checkout == null || checkout == '') {
                    PostRemovedServices();
                    //window.location.href = '/';
                }
            }
        } catch (error) {
            console.error('Error checking session status:', error);
        }
    };


    useEffect(() => {

        const intervalId = setInterval(checkSession, 60000);

        return () => clearInterval(intervalId);

    }, [])*/

  useEffect(() => {
    if (
      window.location.host !== "localhost:5001" &&
      window.location.host !== "20.121.64.153"
    ) {
      (function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", "nxibzvnxfn");
    }
  }, []);
  return (
    <div className="mx-auto">
      <Switch>
        <Route exact path="/" component={HomePageMobile} />
        <Route exact path="/book-service" component={ServiceListPageMobile} />
        <Route
          exact
          path="/customize/step-1"
          component={PriceConfig1PageMobile}
        />
        <Route
          exact
          path="/customize/step-1/Mosquito"
          component={PriceConfig2v2PageMobile}
        />
        <Route
          exact
          path="/customize/step-2"
          component={PriceConfig2PageMobile}
        />
        <Route
          exact
          path="/checkout/payment"
          component={CheckoutPaymentPageMobile}
        />
        <Route
          exact
          path="/checkout/review"
          component={CheckputReviewPageMobile}
        />
        <Route
          exact
          path="/my-home-services"
          component={MyHomeServicesPageMobile}
        />
        <Route
          exact
          path="/create/account"
          component={CreateAccountPageMobile}
        />
        <Route exact path="/signup" component={SignupPageMobile} />
        <Route exact path="/login" component={LoginPageMobile} />
        <Route exact path="/forgot-password" component={ResetPassword} />
        <Route
          exact
          path="/verify-reset-password"
          component={VerifyResetPassword}
        />
        <Route exact path="/tell-us-about-you" component={AboutYou} />
        <Route exact path="/payment/savedcards" component={PaymentSavedCards} />
        <Route
          exact
          path="/payment/history"
          component={PaymentHistoryPageMobile}
        />
        <Route exact path="/my-profile" component={MyProfilePageMobile} />
        <Route exact path="/customer-support" component={CustomerSupport} />
        <Route exact path="/address/my" component={MyAddresses} />
        <Route
          exact
          path="/address/select"
          component={SelectAddressPageMobile}
        />
        <Route exact path="/my-services" component={MyServicesPageMobile} />
        <Route
          exact
          path="/inspection-report"
          component={InseptionReportPageMobile}
        />
        <Route exact path="/services/service-plans" component={ServicePlans} />
        <Route exact path="/services/roof-types" component={RoofTypesPage} />
              <Route exact path="/error-page" component={ErrorPage} />

              <Route exact path="/pay-my-bill" component={PayMyBillPage} />
              <Route exact path="/pay-my-bill-summary" component={PayMyBillSummaryPage} />
              <Route exact path="/pay-my-bill-complete" component={PayMyBillCompletePage} />
      </Switch>
    </div>
  );
};

export default App;
